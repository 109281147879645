import LinkListBlockModel from '../../Blocks/LinkListBlock/Model/LinkListBlockModel.interface';
import LinkBlock from './LinkBlock';

type PropType = {
  content: LinkListBlockModel;
  blockId: any;
};

function LinkListBlock({
  content: { heading, bodyText, links, layout, backgroundColor, whiteText },
  blockId,
}: PropType) {
  return (
    <LinkBlock
      heading={heading}
      bodyText={bodyText}
      layout={layout}
      backgroundColor={backgroundColor}
      whiteText={whiteText}
      iconLinks={links}
      blockId={blockId}
    />
  );
}

export default LinkListBlock;
