import IconLink from '../../Atoms/Links/IconLink';
import { H3 } from '../../Atoms/Typography/Headings/Heading';
import { styled } from '../../stitches.config';
import DocumentModel from '../../Assets/Models/DocumentModel.interface';
import GridItem from '../../Atoms/Grids/GridItem';
import { LinkType } from '../../Enums/LinkType.enum';
import Link from '../../Shared/Models/Link.interface';
import IconLinkModel from '../../Blocks/LinkListBlock/Model/IconLinkModel.interface';
import useMedia from '../../Shared/Hooks/useMedia';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import Grid from '../../Atoms/Grids/Grid';

type PropType = {
  heading?: string;
  bodyText: string;
  layout: string;
  links?: any[];
  backgroundColor: string;
  whiteText: boolean;
  type?: string;
  iconLinks?: IconLinkModel[];
  blockId?: any;
};

function LinkBlock({
  heading,
  bodyText,
  layout,
  links,
  backgroundColor,
  whiteText,
  type,
  iconLinks,
  blockId,
}: PropType) {
  const isMobile = useMedia(mediaQueryTypes.mediaMaxMedium);

  return (
    <GridItem layout={layout}>
      <Root
        css={{ backgroundColor: backgroundColor }}
        white={whiteText}
        data-litium-block-id={blockId}
      >
        {heading && <H3 color={whiteText ? 'white' : 'p'}>{heading}</H3>}
        {bodyText && (
          <div
            dangerouslySetInnerHTML={{
              __html: bodyText,
            }}
          />
        )}
        {type === LinkType.Arrow && (
          <div>
            {links &&
              links.map((link: Link) => (
                <LinkWrapper key={link.href}>
                  <IconLink
                    key={link.href}
                    href={link.href}
                    target={link.target}
                    whiteText={whiteText}
                    type={LinkType.Arrow}
                  >
                    {link.text}
                  </IconLink>
                </LinkWrapper>
              ))}
          </div>
        )}
        {type === LinkType.Document && (
          <div>
            {links &&
              links.map((link: DocumentModel) => (
                <LinkWrapper key={link.url}>
                  <IconLink
                    key={link.url}
                    href={link.url}
                    whiteText={whiteText}
                    type={LinkType.Document}
                  >
                    {link.description ? link.description : link.name}
                  </IconLink>
                </LinkWrapper>
              ))}
          </div>
        )}
        {!type && (
          <IconLinkWrapper>
            {iconLinks &&
              iconLinks.map((iconLink: IconLinkModel) => (
                <IconLink
                  key={iconLink.link?.href}
                  href={iconLink.link?.href}
                  target={iconLink.link?.target}
                  whiteText={whiteText}
                  type={iconLink.icon}
                  size={!isMobile ? 'xxl' : 'xl'}
                  round={true}
                >
                  <RoundLinkText>
                    {iconLink.link?.text
                      ? iconLink.link?.text
                      : iconLink.link?.title}
                  </RoundLinkText>
                </IconLink>
              ))}
          </IconLinkWrapper>
        )}
      </Root>
    </GridItem>
  );
}

const Root = styled('div', {
  mx: 'auto',
  w: '100%',
  pb: 8,
  '@mediaMinLarge': {
    pb: 0,
    mb: -5,
  },
  variants: {
    white: {
      true: {
        color: '$textSecondary',
      },
    },
  },
});

const RoundLinkText = styled('span', {
  fs: 3.5,
  lineHeight: '16px',
  '@mediaMinMedium': {
    fs: 7,
  },
});

const IconLinkWrapper = styled(Grid, {
  justifyContent: 'center',
  gridTemplateColumns: 'repeat(4, minmax(0px, 1fr))',
  w: 'fit-content',
  mx: 'auto',
  columnGap: '32px',
  rowGap: '24px',
  '@mediaMinMedium': {
    columnGap: '40px',
  },
});

const LinkWrapper = styled('div', {});

export default LinkBlock;
